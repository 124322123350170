.about, .get-started, .partners,  .contact, .windowTinding, .windowTindingContainer, .paintProtection, .commercial, .rockChipRepair, .windowTintingPrices{
    background-repeat: no-repeat;
    background-attachment: fixed;
    background-position: center;
    background-size: cover;
    -webkit-background-size: cover;
    /* -o-background-size: cover;
    -moz-background-size: cover;
    -ms-background-size: cover; */
}

.get-started{
    background: url('../images/getStarted.jpeg') no-repeat center center fixed;
}

.get-started-image{
    background-image: url('../images/getStarted.jpeg');
    background-position: center !important;
    background-size: cover;
}

.reviews{
    background-image: url('../images/Rectangle\ 874.png');  
    background-repeat: no-repeat;
    background-size: cover;
}

.windowTindingContainer{
    background-image: url('../images/Window\ Tinding/2.png') no-repeat center center fixed;
}

.contact{
    background-image: url('../images/Contact/1.png') no-repeat center center fixed;
}

.windowTinding{
    background-image: url('../images/Window\ Tinding/1.png') no-repeat center center fixed;
}


.windowTintingPrices{
    background-image: url('../images/Window\ Tinding/5.png');
}


.paintProtection{
    background-image: url('../images/Paint\ Protection/1.png');
}

.rockChipRepair{
    background-image: url('../images/Rock_chip_repair.webp');
}

.commercial{
    background-image: url('../images/CommercialWindowTint/Commercial_Residential.png');
    background-position: center 30% !important;
}

/* .ant-image-preview-root .ant-image-preview-img{
    height: 80% !important;
} */

@media (max-width: 640px){
    .windowTinding{
        background-image: url('../images/Window\ Tinding/1Mobile.png');
    }
  }