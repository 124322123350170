@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: 'roboto';
  src: url('./fonts/Roboto-Medium.ttf');
}


html{
  scroll-behavior: smooth;
  overflow-x: hidden;
}

body {
  margin: 0;
  font-family: "roboto";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-x: hidden !important;
  overflow-y: auto !important;
  background-color: black;
}

